<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col
            v-if="hasExport || searchEnabled"
            cols="12"
            lg="12"
            :class="`mb-2 d-flex flex-row  align-items-center ${
              hasExport || customButton
                ? 'justify-content-between'
                : ' justify-content-end'
            }`"
          >
            <div
              v-if="hasExport || customButton"
              class="d-flex flex-row align-items-end w-50"
            >
              <div v-if="customButton">
                <b-button
                  variant="gradient-primary"
                  class="mr-1"
                  @click.prevent="$emit('customButton')"
                >
                  <span class=""> {{ customButton.label }} </span>
                  <feather-icon
                    v-if="customButton.icon"
                    :icon="customButton.icon"
                    size="14"
                    class="text-white ml-1"
                  />
                </b-button>
              </div>
              <div v-if="hasExport">
                <b-button-group :disabled="exporting">
                  <b-button disabled variant="outline-danger">
                    <b-spinner v-if="exporting" class="mr-50 p-0" small />
                    <feather-icon v-else icon="DownloadIcon" class="mr-50" />
                    Export
                  </b-button>
                  <b-dropdown variant="outline-danger">
                    <b-dropdown-item
                      v-for="(type, index) in exportType"
                      :key="index"
                      @click.prevent="$emit('handleExport', type.value)"
                    >
                      {{ type.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-button-group>
              </div>
            </div>
            <div v-if="searchEnabled" class="w-30">
              <b-input-group>
                <b-form-input v-model="search" placeholder="Search" />
                <b-input-group-append>
                  <b-input-group-text class="bg-primary text-white">
                    Search
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
          <b-col cols="12">
            <vue-good-table
              ref="my-table"
              @on-selected-rows-change="selectionChanged"
              style-class="vgt-table striped"
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: paginationEnabled,
                perPage: pageLength,
              }"
              :search-options="{
                enable: searchEnabled,
                externalQuery: search,
              }"
              :sort-options="{
                enable: true,
              }"
              :select-options="{
                enabled: checkboxEnabled,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: false, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
            >
              <div slot="emptystate" class="text-center">
                <span v-if="!isLoading">No Data Found</span>
                <b-spinner v-else variant="primary" />
              </div>
              <template slot="table-row" slot-scope="props">
                <slot :props="props" />
              </template>
              <!-- pagination -->
              <template
                v-if="paginationEnabled"
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap p-1">
                  <div class="d-flex align-items-center">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '20', '50']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="selected-row-actions">
                <b-button variant="primary" class="mr-2" v-b-modal.handleAddLP
                  >Edit LogPoints</b-button
                >
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <ValidationObserver ref="addLogpoint">
      <b-modal
        size="lg"
        ref="handleAddLP"
        centered
        title="ADD / REDUCE LP"
        hide-footer
        no-close-on-backdrop
        id="handleAddLP"
      >
        <b-form>
          <b-row>
            <b-col>
              <b-form-group label="Amount LP" class="w-100">
                <b-row>
                  <b-col
                    cols="6"
                    class="pr-0 d-flex justify-content-center align-items-center"
                  >
                    <div class="d-flex justify-content-center">
                      <b-form-radio
                        v-model="addPayload.type"
                        name="Add"
                        value="RESTORE"
                        class="mr-2"
                      >
                        Add
                      </b-form-radio>
                      <b-form-radio
                        v-model="addPayload.type"
                        name="Reduce"
                        value="DEDUCT"
                      >
                        Reduce
                      </b-form-radio>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="LP Amount"
                        rules="required"
                      >
                        <input
                          v-model="addPayload.logpoint"
                          type="number"
                          class="form-control font-small-3"
                          placeholder="LP Amount"
                          aria-label="LP Amount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Remarks" class="w-100">
                <ValidationProvider
                  #default="{ errors }"
                  name="Remarks"
                  class="w-100"
                  rules="required"
                >
                  <input
                    v-model="addPayload.remarks"
                    type="text"
                    class="form-control font-small-3"
                    placeholder="Remarks"
                    aria-label="Remarks"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-2 d-flex justify-content-center">
            <b-button
              @click.prevent="addOrReduceLP()"
              variant="gradient-primary"
              type="submit"
            >
              SUBMIT
            </b-button>
          </b-row>
        </b-form>
      </b-modal>
    </ValidationObserver>
  </b-row>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "MyTable",
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    columns: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Array,
    },
    isLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    customButton: {
      type: Object,
      default: null,
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    paginationEnabled: {
      type: Boolean,
      default: true,
    },
    checkboxEnabled: {
      type: Boolean,
      default: false,
    },
    searchEnabled: {
      type: Boolean,
      default: true,
    },
    hasExport: {
      type: Boolean,
      default: false,
    },
    exporting: {
      type: Boolean,
      default: false,
    },
    exportType: {
      type: Array,
      default: () => [
        { value: "xlsx", text: "Excel" },
        { value: "csv", text: "CSV" },
      ],
    },
  },
  data() {
    return {
      addPayload: { transactions: [], type: "" },
      search: "",
      pageLength: 10,
      selectedId: [],
      selectedIndex: [],
      dateFilter: "",
    };
  },
  methods: {
    selectionChanged() {
      this.addPayload.transactions = [];
      this.addPayload.type = this.$refs["my-table"].selectedRows[0].type;
      for (let x = 0; x < this.$refs["my-table"].selectedRows.length; x++) {
        if (
          this.addPayload.type == this.$refs["my-table"].selectedRows[x].type
        ) {
          this.addPayload.transactions.push(
            this.$refs["my-table"].selectedRows[x].id
          );
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Uh oh! 😲😮",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: "You can only choose 1 type of logpoint to edit. Please try again!",
            },
          });
          this.$emit("getLogPointLogs");
        }
      }
    },
    addOrReduceLP() {
      this.$refs.addLogpoint.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http
            .post(`massEditLogpoint`, this.addPayload)
            .then((response) => {
              if (response.data.status) {
                this.$swal({
                  title: "Success !",
                  icon: "success",
                  text: response.data.message,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$emit("getLogPointLogs");
                    this.$refs.handleAddLP.hide();
                    this.addPayload = {};
                  }
                });
              } else if (response.data.errors) {
                this.$refs.addLogpoint.setErrors(response.data.errors);
              } else {
                this.$swal({
                  title: "Error !",
                  icon: "warning",
                  text: response.data.message,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.go(0);
                  }
                });
              }
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
